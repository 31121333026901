@charset "utf-8";

@import "~bulma/sass/base/_all";
@import "~bulma/sass/elements/_all";
@import "~bulma/sass/form/_all";
@import "~bulma/sass/components/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/helpers/_all";
@import "~bulma/sass/layout/_all";

.box1 {
    &>.header {
        display: block;
        background-color: $background;
        border-radius: 6px 6px 0 0;
        color: $text;

        line-height: 1.25;
        padding: 0.75em 1em;

        margin: -1.25rem -1.25rem 0 -1.25rem;

        &.is-primary {
            background-color: $primary;
            color: #fff;
        }
    }
    &>.body{
        margin: -1.25rem  -1.25rem 0 -1.25rem;
    }
}
