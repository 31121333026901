$baseClr1: #191D38;
$baseClr2: #1F2340;

$primary: #F65C5C ;
$link: #00205C; 

$navbar-height: 5.3rem;

$family-sans-serif : 'Rubik', sans-serif;
$family-primary: $family-sans-serif;

$navbar-background-color : $baseClr1;

// $background : $baseClr2;

// $fullhd : 1500px;

@import "~bulma/sass/utilities/_all";

// $secont-invert: findColorInvert($second);
// $addColors: (
//   "second":($second, $primary)
// );
// $colors: map-merge($colors, $addColors);
