@import "./var.scss";
@import "./bulma.scss";
@import "./fontawesome.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

html,
body,
#root {
    background-color: $baseClr2;
    min-height: 100vh;
    color: #fff;
}

.page-loader {
    width: 80px;
    margin: 150px auto 0 auto;
}

.rootbody {
    min-height: calc(100vh - 372px - 84.8px);
}

select:required:invalid {
    color: #ccc;
}
select > option[value=""][disabled] {
    display: none;
}

select > option {
    color: black;
}

